import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect } from 'react';

// Fix Leaflet's default icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// Component to automatically focus the map on new position
function MapAutoFocus({ position }) {
  const map = useMap();
  useEffect(() => {
    if (position) {
      map.setView(position, map.getZoom()); // Automatically change map center to the new position
    }
  }, [position, map]);

  return null;
}

export default function OSMMap({ latitude, longitude, accuracy = 0 }) {
  const position = [latitude, longitude]; // Use the latitude and longitude from props

  return (
    <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {latitude && longitude && ( // Ensure latitude and longitude are valid
        <>
          {/* Automatically focus the map on the updated position */}
          <MapAutoFocus position={position} />

          {/* Place a marker at the driver's position */}
          <Marker position={position}>
            <Popup>
              Driver's current location: <br />
              Latitude: {latitude.toFixed(4)}, Longitude: {longitude.toFixed(4)} <br />
              Accuracy: {accuracy} meters
            </Popup>
          </Marker>
        </>
      )}
    </MapContainer>
  );
}
