import React from 'react';
import Dashboard from './pages/Dashboard'; // Import the Dashboard component

function App() {
  return (
    <div className="App">
      <Dashboard />
    </div>
  );
}

export default App;
