import React, { useState, useEffect } from 'react';
import OSMMap from '../components/OSMMap'; // Assuming you have a map component to show positions

const AdminDashboard = () => {
  const [driverPosition, setDriverPosition] = useState(null);  // Store driver position
  const [ws, setWs] = useState(null);  // WebSocket connection

  useEffect(() => {
    // Create a WebSocket connection to the server
    const socket = new WebSocket('wss://sahb.alneetham.net/ws');
    setWs(socket);

    // Handle WebSocket connection open event
    socket.onopen = () => {
      console.log('Connected to WebSocket as admin');
      // Register as admin after connection is established
      socket.send(JSON.stringify({ type: 'register', role: 'admin' }));
      console.log('Registered as admin');
    };

    // Handle incoming messages from the WebSocket server
    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log(`Received data from server: ${JSON.stringify(data)}`);

      // Check if the message is position data for driverId: 1
      if (data.driverId === 1) {
        console.log(`Updating position for driverId: 1`);
        setDriverPosition(data);  // Update the driver's position in state
      }
    };

    // Handle WebSocket close event
    socket.onclose = (event) => {
      console.log('WebSocket connection closed', event);
    };

    // Handle WebSocket error event
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Cleanup the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Admin Dashboard: Real-time Tracking of Driver 1</h1>

      {driverPosition ? (
        <div style={{ marginTop: '30px', border: '2px solid #d51913', padding: '20px' }}>
          <h3>Tracking Driver 1</h3>
          <OSMMap
            latitude={driverPosition.latitude}
            longitude={driverPosition.longitude}
            accuracy={10} // You can make this dynamic if you wish
          />
        </div>
      ) : (
        <p>Waiting for driver position data...</p>
      )}
    </div>
  );
};

export default AdminDashboard;
